////
/// @group elements-h
////

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  margin: 0 0 var(--d-spacing--s) 0;
}

h1 {
  font-size: var(--d-font-size-h1);
  line-height: var(--d-line-height-h1);
}

h2 {
  font-size: var(--d-font-size-h2);
  line-height: var(--d-line-height-h2);
}

h3 {
  font-size: var(--d-font-size-h3);
  line-height: var(--d-line-height-h3);
}

h4 {
  font-size: var(--d-font-size-h4);
  line-height: var(--d-line-height-h4);
}

h5 {
  font-size: var(--d-font-size-h5);
  line-height: var(--d-line-height-h5);
}

h6 {
  font-size: var(--d-font-size-h6);
  line-height: var(--d-line-height-h6);
}

