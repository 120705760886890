////
/// @group helper-cursor
////

/// Displays a pointer as the cursor on hovering the element.
.dekra-cursor-pointer {
  cursor: pointer !important
}

/// Displays a not allowed cursor on hovering the element
.dekra-cursor-not-allowed {
  cursor: not-allowed !important
}
