////
/// @group helper-border
////
$sizes: "--xl", "--l", "";

/// Removes all borders of an element
.dekra-no-border {
  border: none !important;
}

@mixin inner($name, $direction) {
  @each $size in $sizes {
    $sizeShort: str-slice($size, 2, str-length($size));
    .dekra-#{$name}#{$direction}#{$sizeShort} {
      #{$name}#{$direction}: var(--d-border#{$size}) !important;
    }
    .dekra-#{$name}#{$direction}-accent#{$sizeShort} {
      #{$name}#{$direction}: var(--d-border-green#{$size}) !important;
    }
  }
}

@mixin outer($type) {
  @include inner($type, '');
  @include inner($type, '-top');
  @include inner($type, '-right');
  @include inner($type, '-bottom');
  @include inner($type, '-left');
}

@include outer('border')
