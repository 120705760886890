////
/// @group elements-body
////

/// Set the default DEKRA font
body {
  font-size: var(--d-font-size);
  font-family: var(--d-font);
  line-height: var(--d-line-height);
  height: 100%; // TODO: is this really necessary for every body?
}
