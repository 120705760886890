:root {
  // Fonts
  /// Default font
  --d-font: Arial, sans-serif;

  // Official colors
  /// Official DEKRA green
  --d-green: #008143;
  --d-green--dark: #00743C;
  --d-green--contrast: #FFFFFF;
  /// Official DEKRA dark-green
  --d-dark-green: #006B52;
  --d-dark-green--dark: #00604A;
  --d-dark-green--contrast: #FFFFFF;
  /// Official DEKRA red
  --d-red: #CD0303;
  --d-red--dark: #B90303;
  --d-red--contrast: #FFFFFF;
  /// Official DEKRA orange
  --d-orange: #EF7C00;
  --d-orange--dark: #D77000;
  --d-orange--contrast: #000000;
  /// Official DEKRA yellow
  --d-yellow: #FFCC00;
  --d-yellow--dark: #E6B800;
  --d-yellow--contrast: #000000;
  /// Official DEKRA blue
  --d-blue: #002E55;
  --d-blue--dark: #00294D;
  --d-blue--contrast: #FFFFFF;
  /// Official DEKRA lime
  --d-lime: #64B32C;
  --d-lime--dark: #5AA128;
  --d-lime--contrast: #000000;

  // Icons
  --d-icon-size: 16px;
  --d-icon-size--m: 24px;
  --d-icon-size--l: 32px;
  --d-icon-size--xl: 64px;
  --d-icon-size--xxl: 128px;

  // Outlines
  /// Default border around all sides of an element
  /// Larger green border on the bottom of an element

  --d-shadow-color: rgba(0,0,0,0.24);
  --d-shadow--s: var(--d-shadow-color) 0 0 2px;
  --d-shadow--m: var(--d-shadow-color) 0 0 4px;
  --d-shadow--l: var(--d-shadow-color) 0 0 8px;

  --d-shadow-pressed: inset var(--d-shadow-color) 0 0 8px;

  --d-shadow-inset--right: var(--d-shadow-color) inset -4px 0 6px -4px;


  // Typography
  // TODO: Mobile/Breakpoints
  --d-font-size: 16px;
  --d-font-size--small: 14px;
  --d-font-size--descriptive: 12px;
  --d-font-size--headline: 18px;
  --d-line-height: 1.5em;

  // Headlines
  --d-font-size-h1: 40px;
  --d-line-height-h1: 44px;
  --d-font-size-h2: 28px;
  --d-line-height-h2: 32px;
  --d-font-size-h3: 20px;
  --d-line-height-h3: 26px;
  --d-font-size-h4: 16px;
  --d-line-height-h4: 24px;
  --d-font-size-h5: 14px;
  --d-line-height-h5: 21px;
  --d-font-size-h6: 12px;
  --d-line-height-h6: 18px;


  //breakpoints
  // TODO: New
  $dekra-breakpoint-xs: 601px;
  $dekra-breakpoint-s: 901px;
  $dekra-breakpoint-l: 1201px;
  $dekra-breakpoint-xl: 1401px;


  // Spacing
  // TODO: Naming
  --d-spacing--xxs: 4px;
  --d-spacing--xs: 8px;
  --d-spacing--s: 12px;
  --d-spacing: 16px;
  --d-spacing--l: 24px;
  --d-spacing--xl: 32px;
  --d-spacing--xxl: 48px;
  --d-spacing--xxxl: 64px;

  /**************
   * Components
   **************/
  // TODO
  --d-side-nav-width: 280px;
  --d-form-label-prefixed--basis: 300px;

}

/**************
 * TODO: Breakpoints
 **************/
//@media (min-width: $dekra-breakpoint-xs) {
//  :root {
//    --d-font-size: var(--d-font-size-desktop);
//    --d-font-size-h1: 2rem;
//    --d-font-size-h2: 1.75rem;
//    --d-font-size-h3: 1.5rem;
//    --d-font-size-h4: 1.375rem;
//    --d-font-size-h5: 1.25rem;
//    --d-font-size-h6: 1.125rem;
//  }
//}
//
//@media (min-width: $dekra-breakpoint-s) {
//  :root {
//    --d-font-size-h1: 2.25rem;
//    --d-font-size-h2: 2rem;
//    --d-font-size-h3: 1.75rem;
//    --d-font-size-h4: 1.5rem;
//    --d-font-size-h5: 1.25rem;
//    --d-font-size-h6: 1.125rem;
//  }
//}
//
//@media (min-width: $dekra-breakpoint-l) {
//  :root {
//  }
//}
//
//@media (min-width: $dekra-breakpoint-xl) {
//  :root {
//    --d-font-size: var(--d-font-size-widescreen);
//  }
//}


