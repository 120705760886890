////
/// @group helper-spacing
////
$sizes: "--xxxl","--xxl", "--xl", "--l", "", "--s", "--xs", "--xxs";

/// Removes all margins from the element
.dekra-no-margin {
  margin: 0 !important;
}

/// Removes all padding from the element
.dekra-no-padding {
  padding: 0 !important;
}

/// Removes all margin and padding from the element
.dekra-no-spacing {
  padding: 0 !important;
  margin: 0 !important;
}

@mixin inner($name, $direction) {
  @each $size in $sizes {
    $sizeShort: str-slice($size, 2, str-length($size));
    .dekra-#{$name}#{$direction}#{$sizeShort} {
      #{$name}#{$direction}: var(--d-spacing#{$size}) !important;
    }
  }
}

@mixin outer($type) {
  @include inner($type, '');
  @include inner($type, '-top');
  @include inner($type, '-right');
  @include inner($type, '-bottom');
  @include inner($type, '-left');
}


@include outer('padding');
@include outer('margin');
