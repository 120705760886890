////
/// @group elements-globals
////

// TODO: Other solution than *, do we really want this on all elements?
/// Set all elements to box sizing: border box as margins and padding should be included in the calculation.
* {
  box-sizing: border-box;
}

// TODO: Other solution than *?
// We remove all outlines by default.
// Outlines/Shadows should be implemented the elements that require them specifically.
*:focus {
  outline: none;
}



