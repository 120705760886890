////
/// @group helper-display
////
// TODO: Breakpoints

/// Displays the content as a block
.dekra-display-block {
  display: block !important;
}

/// Displays the content as a inline block
.dekra-display-inline-block {
  display: inline-block !important;
}


@media (max-width: 600px) {
  /// Hides Content below 600px
  .hidden-s {
    display: none !important;
  }
}

@media (max-width: 900px) {
  /// Hides Content below 900px
  .hidden-m {
    display: none !important;
  }
}

@media (max-width: 1200px) {
  /// Hides Content below 1200px
  .hidden-l {
    display: none !important;
  }
}

@media (max-width: 1400px) {
  /// Hides Content below 1400px
  .hidden-xl {
    display: none !important;
  }
}
