
section {
  max-width: 787px;
}

section.wide {
  max-width: 988px;
}

section.centered {
  margin: 0 auto;
}
