:root,
[data-spacing="default"] {
  // Variables
  --d-spacing-themed--xxs: 4px;
  --d-spacing-themed--xs: 8px;
  --d-spacing-themed--s: 12px;
  --d-spacing-themed: 16px;
  --d-spacing-themed--l: 24px;
  --d-spacing-themed--xl: 32px;
  --d-spacing-themed--xxl: 48px;
  --d-spacing-themed--xxxl: 64px;

  // Components
  --dc-stepper-height: 40px;
  --dc-stepper-bubble-height: 34px;
}
