@import "../variables";
@import "../themes";
////
/// @group elements-table
/// TODO: Rework
////

table {
  display: block; // Makes the table scrollable instead of the whole page if the table overflows
  overflow: auto;
  border-spacing: 0;
  border-collapse: collapse;
  color: var(--d-font-color);

  thead {
    tr {
      background: var(--d-background--content);
      border-bottom: var(--d-border);
    }
  }

  tr {
    background: var(--d-background--layer-1);

    td, th {
      text-align: left;
      padding: var(--d-spacing-themed--s);
    }

    th {
      font-size: var(--d-font-size--small);
      font-weight: bold;
    }

    td {
      vertical-align: top;
    }

    &:nth-child(2n) {
      background: var(--d-background);
    }

    &:not(:last-child) {
      border-bottom: var(--d-border);
    }
  }

  tr.error {
    background: var(--d-red--20);

    &:nth-child(2n) {
      background: var(--d-red--10);
    }
  }

  tr.success {
    background: var(--d-green--20);

    &:nth-child(2n) {
      background: var(--d-green--10);
    }
  }

  tr.info {
    background: var(--d-blue--20);

    &:nth-child(2n) {
      background: var(--d-blue--10);
    }
  }

  tr.warning {
    background: var(--d-orange--20);

    &:nth-child(2n) {
      background: var(--d-orange--10);
    }
  }

}

