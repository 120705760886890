////
/// @group helper-text
////

/// Display text as bold
.dekra-bold {
  font-weight: bold !important;
}

/// Display text as italic
.dekra-italic {
  font-style: italic !important;
}

/// Displays the text as DEKRA default text
.dekra-text {
  font-size: var(--d-font-size);
  line-height: var(--d-line-height);
  color: var(--d-font-color);
}

/// Displays the the text as a headline
.dekra-text--headline {
  font-size: var(--d-font-size--headline);
  line-height: var(--d-line-height);
  color: var(--d-font-color);
  font-weight: bold;
}

/// Displays the text as DEKRA default text in grey
.dekra-text--secondary {
  font-size: var(--d-font-size);
  line-height: var(--d-line-height);
  color: var(--d-font-color--secondary);
}

/// Display the text as small DEKRA default text
.dekra-text--small {
  font-size: var(--d-font-size--small);
  line-height: var(--d-line-height);
  color: var(--d-font-color);
}

/// Display the text as small DEKRA default text in grey
.dekra-text--small--secondary {
  font-size: var(--d-font-size--small);
  line-height: var(--d-line-height);
  color: var(--d-font-color--secondary);
}

/// Display the text as small DEKRA default text
.dekra-text--descriptive {
  font-size: var(--d-font-size--descriptive);
  line-height: var(--d-line-height);
  color: var(--d-font-color);
}

/// Display the text as small DEKRA default text in grey
.dekra-text--descriptive--secondary {
  font-size: var(--d-font-size--descriptive);
  line-height: var(--d-line-height);
  color: var(--d-font-color--secondary);
}
