@mixin col-breakpoints($name) {
  .dekra-grid {
    .dekra-row {
      .dekra-col#{$name}-12 {
        @include col;
        width: 100%;
      }

      .dekra-col#{$name}-8 {
        @include col;
        width: 66.6666666667%;
      }

      .dekra-col#{$name}-6 {
        @include col;
        width: 50%;
      }

      .dekra-col#{$name}-4 {
        @include col;
        width: 33.333333333%;
      }

      .dekra-col#{$name}-3 {
        @include col;
        width: 25%;
      }
    }

    &.dekra-grid--no-spacing {

      .dekra-row {
        margin: 0;

        > * {
          padding: 0;
        }
      }
    }
  }
}

@mixin col() {
  padding: 0 calc(var(--d-spacing--l) / 2);
  flex: 0 0 auto;
}

.dekra-grid {

  .dekra-row {
    margin: 0 calc(var(--d-spacing--l) / -2);

    display: flex;
    flex-wrap: wrap;

    .dekra-col {
      padding: 0 calc(var(--d-spacing--l) / 2);
      flex: 1;
    }

    .dekra-col-8 {
      @include col;
      width: 66.6666666667%;
    }

    .dekra-col-6 {
      @include col;
      width: 50%;
    }

    .dekra-col-4 {
      @include col;
      width: 33.333333333%;
    }

    .dekra-col-3 {
      @include col;
      width: 25%;
    }

  }

  &.dekra-grid--no-spacing {

    .dekra-row {
      margin: 0;

      > * {
        padding: 0;
      }
    }
  }
}

@include col-breakpoints('');

@media (min-width: 601px) {
  @include col-breakpoints('-s');
}

@media (min-width: 901px) {
  @include col-breakpoints('-m');
}

@media (min-width: 1201px) {
  @include col-breakpoints('-l');
}

@media (min-width: 1401px) {
  @include col-breakpoints('-xl');
}

