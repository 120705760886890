/* You can add global styles to this file, and also import other style files */
@import "@dekra-ds/core/scss/bundle.scss";

* {
  box-sizing: border-box;
}

.page-spacing {
  padding: var(--d-spacing) var(--d-spacing) 0;
}
