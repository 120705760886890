////
/// @group elements-a
////

/// Default styling of links according to the DEKRA Design System
a {
  color: var(--d-green--themed);
  text-decoration: underline;

  &:active,
  &:hover {
    color: var(--d-font-color);
    text-decoration: underline;
  }

  &:focus {
    box-shadow: var(--d-focus);
  }
}
