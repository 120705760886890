@mixin variables-light() {
  // Fonts
  --d-font-color: #292929;
  --d-font-color--secondary: #707070;
  --d-font-color--contrast: #FFFFFF;

  // Colors
  // Light theme colors stay identical
  --d-green--themed: var(--d-green);
  --d-dark-green--themed: var(--d-dark-green);
  --d-red--themed: var(--d-red);
  --d-orange--themed: var(--d-orange);
  --d-yellow--themed: var(--d-yellow);
  --d-blue--themed: var(--d-blue);
  --d-lime--themed: var(--d-lime);
  --d-disabled-color: #CCCCCC;
  --d-disabled-color--contrast: #7B7B7B;

  --d-green--10: #E6F2EC;
  --d-dark-green--10: #E6F0EE;
  --d-red--10: #FAE6E6;
  --d-orange--10: #FDF2E6;
  --d-yellow--10: #FFFAE6;
  --d-blue--10: #E6EAEE;
  --d-lime--10: #F0F7EA;

  --d-green--20: #CCE6D9;
  --d-dark-green--20: #CCE1DC;
  --d-red--20: #F5CDCD;
  --d-orange--20: #FCE5CC;
  --d-yellow--20: #FFF5CC;
  --d-blue--20: #CCD5DD;
  --d-lime--20: #E0F0D5;

  //--d-accent--themed: var(--d-green--themed);
  //--d-success--themed: var(--d-green--themed);
  //--d-error--themed: var(--d-red--themed);
  //--d-warning--themed: var(--d-orange--themed);
  //--d-info--themed: var(--d-blue--themed);

  // Outlines
  /// Default border around all sides of an element
  /// Larger green border on the bottom of an element
  --d-border-color: #E5E5E5;
  --d-border: 1px solid var(--d-border-color);
  --d-border--l: 2px solid var(--d-border-color);
  --d-border--xl: 4px solid var(--d-border-color);
  --d-border-green: 1px solid var(--d-green--themed);
  --d-border-green--l: 2px solid var(--d-green--themed);
  --d-border-green--xl: 4px solid var(--d-green--themed);
  --d-border-red: 1px solid var(--d-red--themed);
  --d-border-red--l: 2px solid var(--d-red--themed);
  --d-border-red--xl: 4px solid var(--d-red--themed);
  --d-border-blue: 1px solid var(--d-blue--themed);
  --d-border-blue--l: 2px solid var(--d-blue--themed);
  --d-border-blue--xl: 4px solid var(--d-blue--themed);
  --d-border-orange: 1px solid var(--d-orange--themed);
  --d-border-orange--l: 2px solid var(--d-orange--themed);
  --d-border-orange--xl: 4px solid var(--d-orange--themed);

  --d-border-size--themed: 1px;
  --d-border--themed: var(--d-border);

  --d-focus-color: #393939;
  --d-focus: var(--d-focus-color) 0 0 0 1px; // TODO: not in figma
  --d-focus--l: var(--d-focus-color) 0 0 0 2px; // TODO: not in figma

  // Layout colors
  --d-background: #FFFFFF;
  --d-background--secondary: #F5F5F5;
  --d-background--content: #FFFFFF;
  --d-background--layer-1: #EBEBEB;
  --d-background--layer-2: #E0E0E0;
  --d-background--layer-3: #D6D6D6;

  --d-backdrop: rgba(0, 0, 0, 0.4);

  //shadows
  --d-shadow--green: var(--d-green--themed) 0 0 4px;
  --d-shadow--red: var(--d-red--themed) 0 0 4px;

  // component styles
  --dc-input-background: var(--d-background--content);
}

:root {
  @include variables-light();
}
