////
/// @group helper-background
////

/// Default background color, mostly used for the whole page
.dekra-background {
  background: var(--d-background);
}

/// Default green background color
.dekra-background-green {
  background-color: var(--d-green);
  color: var(--d-green--contrast);
}

/// Default dark green background color
.dekra-background-dark-green {
  background-color: var(--d-dark-green);
  color: var(--d-dark-green--contrast);
}

/// Default red background color
.dekra-background-red {
  background-color: var(--d-red);
  color: var(--d-red--contrast);
}

/// Default orange background color
.dekra-background-orange {
  background-color: var(--d-orange);
  color: var(--d-orange--contrast);
}

/// Default yellow background color
.dekra-background-yellow {
  background-color: var(--d-yellow);
  color: var(--d-yellow--contrast);
}

/// Default blue background color
.dekra-background-blue {
  background-color: var(--d-blue);
  color: var(--d-blue--contrast);
}

/// Default lime background color
.dekra-background-lime {
  background-color: var(--d-lime);
  color: var(--d-lime--contrast);
}

/// Default green background color, adjusted to the current theme
.dekra-background-green--themed {
  background-color: var(--d-green--themed);
  color: var(--d-green--contrast);
}

/// Default dark green background color, adjusted to the current theme
.dekra-background-dark-green--themed {
  background-color: var(--d-dark-green--themed);
  color: var(--d-dark-green--contrast);
}

/// Default red background color, adjusted to the current theme
.dekra-background-red--themed {
  background-color: var(--d-red--themed);
  color: var(--d-red--contrast);
}

/// Default orange background color, adjusted to the current theme
.dekra-background-orange--themed {
  background-color: var(--d-orange--themed);
  color: var(--d-orange--contrast);
}

/// Default yellow background color, adjusted to the current theme
.dekra-background-yellow--themed {
  background-color: var(--d-yellow--themed);
  color: var(--d-yellow--contrast);
}

/// Default blue background color, adjusted to the current theme
.dekra-background-blue--themed {
  background-color: var(--d-blue--themed);
  color: var(--d-blue--contrast);
}

/// Default lime background color, adjusted to the current theme
.dekra-background-lime--themed {
  background-color: var(--d-lime--themed);
  color: var(--d-lime--contrast);
}


/// Secondary background color, used for side navigation
.dekra-background--secondary {
  background: var(--d-background--secondary);
}

/// Primary background color for content
.dekra-background--content {
  background: var(--d-background--content);
}

/// Layer color to further distinguish content
.dekra-background--layer-1 {
  background: var(--d-background--layer-1);
}

/// Layer color to further distinguish content
.dekra-background--layer-2 {
  background: var(--d-background--layer-2);
}

/// Layer color to further distinguish content
.dekra-background--layer-3 {
  background: var(--d-background--layer-3);
}
