////
/// @group helper-flex
////

/// Displays the content as flexbox.
.dekra-flex {
  display: flex !important;
}

/// Displays the elements in a column.
.dekra-flex--column {
  flex-direction: column !important;
}
