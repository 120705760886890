////
/// @group helper-width
////

/// Stretches the element to 100% width
.dekra-width-100 {
  width: 100% !important;
}

/// Stretches the element to 50% width
.dekra-width-50 {
  width: 50% !important;
}

/// Stretches the element to 25% width
.dekra-width-25 {
  width: 25% !important;
}

