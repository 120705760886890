[data-spacing="condensed"] {
  // Variables
  --d-spacing-themed--xxs: 2px;
  --d-spacing-themed--xs: 4px;
  --d-spacing-themed--s: 8px;
  --d-spacing-themed: 12px;
  --d-spacing-themed--l: 16px;
  --d-spacing-themed--xl: 24px;
  --d-spacing-themed--xxl: 32px;
  --d-spacing-themed--xxxl: 48px;

  // Components
  --dc-stepper-height: 40px;
  --dc-stepper-bubble-height: 34px;

}
