@mixin variables-dark() {
  // Fonts
  --d-font-color: #E0E0E0;
  --d-font-color--secondary: #8B8B8B;
  --d-font-color--inverted: #000000;

  // Colors
  // 4th color tint at https://colors.artyclick.com/color-shades-finder
  --d-green--themed: #32A86F;
  --d-dark-green--themed: #2D977F;
  --d-blue--themed: #295B87;
  --d-red--themed: var(--d-red);
  --d-orange--themed: var(--d-orange);
  --d-yellow--themed: var(--d-yellow);
  --d-lime--themed: var(--d-lime);
  --d-disabled-color: #7B7B7B;
  --d-disabled-color--contrast: #CCCCCC;

  // 70% shade at https://maketintsandshades.com/
  --d-green--10: #0f3221;
  --d-dark-green--10: #0d2d26;
  --d-red--10: #3D0101;
  --d-orange--10: #482500;
  --d-yellow--10: #4C3D00;
  --d-blue--10: #0c1b28;
  --d-lime--10: #1E360D;

  // 60% shade at https://maketintsandshades.com/
  --d-green--20: #14432c;
  --d-dark-green--20: #123c33;
  --d-red--20: #520101;
  --d-orange--20: #603200;
  --d-yellow--20: #665200;
  --d-blue--20: #102436;
  --d-lime--20: #284812;

  //--d-accent--themed: var(--d-green--themed);
  //--d-success--themed: var(--d-green--themed);
  //--d-error--themed: var(--d-red--themed);
  //--d-warning--themed: var(--d-orange--themed);
  //--d-info--themed: var(--d-blue--themed);

  // Outlines
  /// Default border around all sides of an element
  /// Larger green border on the bottom of an element
  --d-border-color: #232323;
  --d-border: 1px solid var(--d-border-color);
  --d-border--l: 2px solid var(--d-border-color);
  --d-border--xl: 4px solid var(--d-border-color);
  --d-border-green: 1px solid var(--d-green--themed);
  --d-border-green--l: 2px solid var(--d-green--themed);
  --d-border-green--xl: 4px solid var(--d-green--themed);
  --d-border-red: 1px solid var(--d-red--themed);
  --d-border-red--l: 2px solid var(--d-red--themed);
  --d-border-red--xl: 4px solid var(--d-red--themed);
  --d-border-blue: 1px solid var(--d-blue--themed);
  --d-border-blue--l: 2px solid var(--d-blue--themed);
  --d-border-blue--xl: 4px solid var(--d-blue--themed);
  --d-border-orange: 1px solid var(--d-orange--themed);
  --d-border-orange--l: 2px solid var(--d-orange--themed);
  --d-border-orange--xl: 4px solid var(--d-orange--themed);

  --d-border-size--themed: 0px;
  --d-border--themed: none;

  --d-focus-color: #E0E0E0;
  --d-focus: var(--d-focus-color) 0 0 0 1px; // TODO: not in figma
  --d-focus--l: var(--d-focus-color) 0 0 0 2px; // TODO: not in figma

  // Layout colors
  --d-background: #141414;
  --d-background--secondary: #0A0A0A;
  --d-background--content: #1F1F1F;
  --d-background--layer-1: #292929;
  --d-background--layer-2: #333333;
  --d-background--layer-3: #3C3C3C;


  --d-backdrop: rgba(255, 255, 255, 0.2);

  --d-shadow--green: var(--d-green--themed) 0 0 4px;
  --d-shadow--red: var(--d-red--themed) 0 0 4px;


  // component styles
  --dc-input-background: var(--d-background);
}

/* Dark theme */
[data-theme="dark"] {
  @include variables-dark();
}
