.dekra-form {
  margin-bottom: var(--d-spacing-l);

  .dekra-form__item {
    display: flex;
    margin-bottom: var(--d-spacing);
  }

  .dekra-form__label {
    flex-basis: 300px;
    flex-grow: 0;
    font-weight: bold;
    line-height: var(--d-line-height);
    font-size: var(--d-font-size--small);
    padding: var(--d-spacing-themed--xs) 0;
  }

  .dekra-form__buttons-prefix {
    flex-basis: 300px;
    margin-right: var(--d-spacing);
  }

  .dekra-form__buttons {
    margin-bottom: var(--d-spacing);
    width: 100%;
    display: flex; // remove inline-block spacing

    dekra-button + dekra-button {
      margin-left: var(--d-spacing);
    }
  }
}
