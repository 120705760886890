@import "themes/spacing-default.variables";
@import "themes/spacing-condensed.variables";

@import "themes/theme-light.variables";

@import "themes/theme-dark.variables";


/* Styles for users who prefer dark mode at the OS level */
@media (prefers-color-scheme: dark) {
  /* defaults to dark theme */
  :root {
    @include variables-dark();
  }
  /* Override dark mode with light mode styles if the user decides to swap */
  [data-theme="light"] {
    @include variables-light();
  }
}
