////
/// @group helper-color
////

/// Default font color
.dekra-font {
  color: var(--d-font-color);
}

/// Default green color
.dekra-green {
  color: var(--d-green);
}

/// Default dark green color
.dekra-dark-green {
  color: var(--d-dark-green);
}

/// Default red color
.dekra-red {
  color: var(--d-red);
}

/// Default orange color
.dekra-orange {
  color: var(--d-orange);
}

/// Default yellow color
.dekra-yellow {
  color: var(--d-yellow);
}

/// Default blue color
.dekra-blue {
  color: var(--d-blue);
}

/// Default lime color
.dekra-lime {
  color: var(--d-lime);
}

/// Default green color, adjusted to the current theme
.dekra-green--themed {
  color: var(--d-green--themed);
}

/// Default dark green color, adjusted to the current theme
.dekra-dark-green--themed {
  color: var(--d-dark-green--themed);
}

/// Default red color, adjusted to the current theme
.dekra-red--themed {
  color: var(--d-red--themed);
}

/// Default orange color, adjusted to the current theme
.dekra-orange--themed {
  color: var(--d-orange--themed);
}

/// Default yellow color, adjusted to the current theme
.dekra-yellow--themed {
  color: var(--d-yellow--themed);
}

/// Default blue color, adjusted to the current theme
.dekra-blue--themed {
  color: var(--d-blue--themed);
}

/// Default lime, adjusted to the current theme
.dekra-lime--themed {
  color: var(--d-lime--themed);
}
