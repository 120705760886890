////
/// @group elements-html
////

/// Set the height to 100% as we want the main content to scroll instead of the full page.
/// This enables the header and the side navigation to adjust itself correctly
// TODO: Do we want the 100% for sure?
html {
  height: 100%;
  color: var(--d-font-color);
  background: var(--d-background--secondary);
  font-size: var(--d-font-size);
  line-height: var(--d-line-height);
}
